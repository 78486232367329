<template>
  <sign-page
    title-text="医院职称聘任情况"
    :request="request"
    :column-list="columnList"
    :tableActionsFixed="true"
    ref="page"
    table-size="large"
    :title-menus="[]"
    :export-file-name="exportFileName"
    :tableActions="[{key: 'queue', label: '排队情况'}]"
    @tableAction="tableAction"
    :need-data-file="true">
    <fm-modal v-model="modal" width="1000px" title="排队明细">
      <div style="min-height:20rem" v-if="modal && queueData.length > 0">
        <div v-for="(item, index) in queueData" :key="index">
          <fm-title :title-text="'岗位类型：' + item.type + '    岗位层级:' + item.topLevel + '    职称级别:' + item.mLevel + '    岗位等级:' + item.title + '    剩余岗位数:' + (item.lastNum < 0 ? 0 : item.lastNum)"
          ></fm-title>
          <fm-table-new
            @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'details')"
            size="small"
            border="row"
            :stripe="false"
            emptyPlaceholder="-"
            :columns="columnsW"
            :data-list="item.workers"
          ></fm-table-new>
        </div>
      </div>
      <div style="min-height:20rem;display: flex;justify-content: center;align-items: center;font-size: 2rem;color: #999;" v-if="queueData.length === 0">
        未满足岗位晋升条件
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '@/api'

import {
  dealQueryData
} from '@/syslib/worker_job_title'

import {
  dateOperating
} from '@/fmlib'

export default {
  computed: {
    baseColumnsW () {
      return [{
        title: '序号',
        dataType: Number,
        sort: true,
        fixed: 'left',
        field: 'index'
      },
      {
        title: '员工编码',
        fixed: 'left',
        sort: true,
        field: 'code'
      },
      {
        title: '员工姓名',
        fixed: 'left',
        sort: true,
        field: 'name'
      },
      {
        title: '适岗执业资格证',
        sort: true,
        field: 'sgCertificateTitle'
      },
      {
        title: '适岗执业资格证获得时间',
        dataType: Date,
        sort: true,
        field: 'sgGetDate',
        render: (h, rowData) => {
          return h('div', rowData.sgGetDate ? rowData.sgGetDate.slice(0, 10) : '-')
        }
      },
      {
        title: '本岗累积工作年限',
        sort: true,
        dataType: Number,
        field: 'cJobTitleDiffYears'
      },
      {
        title: '当前岗位',
        sort: true,
        field: 'cJobTitleTitles'
      },
      {
        title: '当前岗位聘任起始时间',
        dataType: Date,
        sort: true,
        field: 'cJobTitleStartDates'
      },
      {
        title: '参加工作时间',
        dataType: Date,
        sort: true,
        field: 'correctionDate',
        render: (h, rowData) => {
          return h('div', rowData.correctionDate ? rowData.correctionDate.slice(0, 10) : '-')
        }
      },
      {
        title: '入职单位时间',
        dataType: Date,
        sort: true,
        field: 'entryDate',
        render: (h, rowData) => {
          return h('div', rowData.entryDate ? rowData.entryDate.slice(0, 10) : '-')
        }
      }]
    },
    columnsW () {
      return this.$getTableConfig('details', this.baseColumnsW)
    },
    columnList () {
      return [{
        title: '员工编码',
        width: 80,
        sort: true,
        fixed: 'left',
        field: 'workerCode'
      },
      {
        title: '员工姓名',
        width: 100,
        sort: true,
        fixed: 'left',
        field: 'workerName'
      },
      {
        title: '岗位类型',
        sort: true,
        field: 'jobTitleType'
      },
      {
        title: '岗位层级',
        sort: true,
        field: 'topLevel'
      },
      {
        title: '职称级别',
        sort: true,
        field: 'mLevel'
      },
      {
        title: '岗位等级',
        sort: true,
        field: 'jobTitleTitle'
      },
      {
        title: '最高执业资格证',
        sort: true,
        field: 'zgzgz'
      },
      {
        title: '最高执业资格证获得时间',
        sort: true,
        field: 'zgzgzsj',
        render: (h, rowData) => {
          return h('div', rowData.zgzgzsj ? rowData.zgzgzsj.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.zgzgzsj ? rowData.zgzgzsj.slice(0, 10) : ''
        }
      },
      {
        title: '本岗累积工作年限',
        sort: true,
        dataType: Number,
        field: 'diffYear'
      },
      {
        title: '当前岗位上岗时间',
        sort: true,
        field: 'startDate',
        render: (h, rowData) => {
          return h('div', rowData.startDate ? rowData.startDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.startDate ? rowData.startDate.slice(0, 10) : ''
        }
      },
      {
        title: '参加工作时间',
        sort: true,
        field: 'workerCorrectionDate',
        render: (h, rowData) => {
          return h('div', rowData.workerCorrectionDate ? rowData.workerCorrectionDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.workerCorrectionDate ? rowData.workerCorrectionDate.slice(0, 10) : ''
        }
      },
      {
        title: '入职单位时间',
        sort: true,
        field: 'workerEntryDate',
        render: (h, rowData) => {
          return h('div', rowData.workerEntryDate ? rowData.workerEntryDate.slice(0, 10) : '')
        },
        form: (rowData) => {
          return rowData.workerEntryDate ? rowData.workerEntryDate.slice(0, 10) : ''
        }
      }]
    }
  },
  methods: {
    timeBj (t1, t2) {
      return dateOperating.computeDay({days: 0, date: t1}) > dateOperating.computeDay({days: 0, date: t2})
    },
    exportFileName () {
      return '医院上岗情况' + this.$datetime.format(new Date(), 'YMD')
    },
    tableAction (parm) {
      this.queueData = dealQueryData(parm.data.queueInfos)
      this.modal = true
    },
    async loadData () {
      let data2 = await request.getCurrent()
      let levels = ['员级', '助理级', '中级', '副高级', '正高级']
      data2.forEach(v => {
        let zgzgz = null
        if (v.workerCertificateProQuaDatas && v.workerCertificateProQuaDatas.length > 0) {
          let i = levels.length - 1
          while (!zgzgz && i > -1) {
            zgzgz = v.workerCertificateProQuaDatas.find(v1 => v1.certificateMLevel === levels[i])
            i = i - 1
          }
        }
        if (zgzgz) {
          v.zgzgz = zgzgz.certificateTitle
          v.zgzgzsj = zgzgz.getDate
        }
      })
      data2.sort((a, b) => a.code - b.code)
      return data2
    }
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      modal: false,
      queueData: []
    }
  }
}
</script>
